.offers-hub-table{
    margin-top: 15px;
    background: #fff;
    table{
        width: 100%;
        border-collapse: collapse !important;
    thead{
        color: #1F2937;
        background-color: #F7F7F8;
        td{
        padding: 12px 0px;
        font-size: 14px;
        font-weight: 600;
        div {
            display: flex;
            cursor: pointer;

            .sort {
                padding-left: 7px;
            }
        }
    }
    td:nth-child(1){
        padding-left: 10px;
    }
        }
        tbody{
            tr{
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                border-bottom: 1px solid #E9EBEF;
                color: #414141;
                cursor: pointer;

                td{
                    padding: 18px 0px;
                }
                td:nth-child(1){
                    padding-left: 10px;
                }
                .offer-table-desc {
                  width: 18%;
                  padding-right: 12px;
                }
                .offer-table-merchant {
                  width: 14%;
                  padding-right: 12px;
                }
                button{
                    border: 0px;
                    padding: 10px 16px;
                    border-radius: 50px;
                    text-transform: capitalize;
                    font-weight: 600;
                }
                .LIVE{
                    background-color:#F0FDF4;
                    color:#14532D;
                    
                }
                .live{
                    background-color:#F0FDF4;
                    color:#14532D;
                }
                .ready_to_publish{
                    background-color:#E6EDFD;
                    color:#1E3A8A;
                }
                .pending{
                  background-color: #FFFBEB;
                  color:#D97706;
                  
            }
                .paused{
                      background-color: #FFFBEB;
                      color:#D97706;
                      
                }
                .DRAFT{
                    background-color:#FEF2F2;
                    color:#DC2626;
                    
                }
                .draft{
                  background-color:#FEF2F2;
                  color:#DC2626;
                  
              }
                .READY_TO_PUBLISH {
                    background-color:rgba(230, 237, 253, 1);
                    color:rgba(0, 45, 142, 1);
                }
                .ready_to_publish {
                  background-color:rgba(230, 237, 253, 1);
                  color:rgba(0, 45, 142, 1);
              }
              .readytopublish {
                background-color:rgba(230, 237, 253, 1);
                color:rgba(0, 45, 142, 1);
            }
                .action-div{
                    display: grid;
                    grid-template-columns: auto auto auto;
                }
                .action{
                    text-decoration: underline;
                    // color:#1A5DEE;
                    cursor: pointer;
                }
                .actionButton{
                  position: relative;
                  width: max-content;
                  padding: 5px;
                  cursor: pointer;
                  padding-bottom: 5px;
                  span{
                    font-size: 16px;
                    font-weight: 800;
                }
                  
                }
                .actions-dropdownlist{ 
                  box-shadow: 0px 0px 32px 0px #0000001F;                            
                  position: absolute;
                  background-color: #fff;
                  min-width: 170px;
                  transition: transform 0.3s ease, opacity 0.3s ease;

                  &.upwards{
                    bottom:36px;
                    right: 5px;
                    transform: translateY(10px);
                  }
                  &.downwards{
                    top:calc(100% + 12px);
                    right: 5px;
                    transform: translateY(-10px);
                  }
                }
                .open-action {
                  z-index: 9;
                }
                .close-action {
                  opacity: 0;
                }
                .action-new{
                  
                  padding: 8px 17px 7px 17px  !important;
                  color: #000 !important;
                  width: 100%;
                  text-align: left;
                  background-color: #fff;  
                  border-radius: 0;
                  font-size: 14px;
                  font-weight: 500;
                  line-height:21px;
                  
                }

                .action-new:hover{
                  cursor: pointer;
                  color: #fff !important;
                  background-color: #004BEC;
                }

                .upwards.open{
                  display: block;
                  opacity: 1;
                  visibility: visible;
                  transform: translateY(0px);
                }

                .downwards.open{
                  display: block;
                  opacity: 1;
                  visibility: visible;
                  transform: translateY(0px);
                }

                .seperator {
                    color: #D1D5DB;
                    margin-left: 12px;
                    margin-right: 12px;
                }
                .pause {
                    color: #EF4444;
                }
                .edit {
                    color: #1A5DEE;
                }
                .resume {
                    color: #F59E0B;
                }
                .publish {
                    color: #15803D;
                }
                .more-actions{
                    position: relative;
                }
                .more-actions-menu{    
                    position: absolute;
                    z-index: 100;
                    background: white;
                    padding: 16px 80px 16px 20px;
                    cursor: pointer;
                    border: 1px solid #E5E7EB;
                    div:not(:nth-child(1)){
                        padding-top: 15px;
                    }
                }
            }
            tr:hover{
              background-color: #F9FAFB;
              font-weight: 600;
              color:#111827;
            }
        }
       
        
}
.cursor{
    cursor:pointer
}
.custom-checkbox {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    // bottom: 100px;
  }
  .rememberText {
    font-size: 14px;
    font-weight: 400;
    color: rgba(31, 41, 55, 1);
    padding-left: 12px;
  }
  
  .custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmarkbox {
    border: 1px solid rgba(233, 235, 239, 1);
    height: 24px;
    width: 24px;
    border-radius: 2px;
    background: #fff;
    cursor: pointer;
  }
  .cb {
    border: 1px solid rgba(0, 75, 236, 1);
  }
  .checkmark {
    position: absolute;
    top: 3px;
    left: 7px;
    height: 14px;
    width: 14px;
    background-color: #fff;
    // border: 1px solid rgba(233, 235, 239, 1);
    border-radius: 2px;
  }
  
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .custom-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  
  .custom-checkbox .checkmark:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 11px;
    border: solid rgba(0, 75, 236, 1);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
.offerDownload{
  margin-left: 30px !important;
  position: relative;
}
.filter-div-offer{
    margin-top: 17px;
    display: flex;
    justify-content: space-between;
    gap:36px;
    color:rgba(107, 114, 128, 1);
    font-size: 14px;
    
    .filter-search {
      // flex-direction: column;
      // margin: 0 !important;
      margin-left: 0;
    }
    .search-heading {
      font-weight: 500;
      margin-left: 0 !important;
      // margin-right: 16px;
      min-width: fit-content;
      // align-items: flex-start !important;
      color: #004BEC;
      .search-name {
          font-weight: 600;
          color: #111827;
          
      }
    }
    .oselect {
      font-size: 13px;
      font-weight: 600;
      color: #111827;
      span {
        color: #6B7280;
      }
    }
    .sep {
      margin: 0 16px !important;
      color: #E5E7EB;
    }
    .sep1 {
      margin-left: 21px !important;
      color: #E5E7EB;
    }
    .arp {
      display: flex;
      color: #004BEC;
      font-size: 13px;
      font-weight: 600;
    }
    div{
        display: flex;
        align-items: center;
        margin-left: 8px;
        cursor: pointer;
    }
    span{
        margin-left: 8px;
    }
    .pagination{
        color: rgba(20, 20, 20, 1);
        font-weight: 600;
        gap: 15px;
        width: max-content;
    }
    .selected-offers{
      font-size: 14px;
      line-height: 21px;
      font-weight: 600;
      color: #1F2937;
      margin-left: 0;
      min-width: fit-content;

      span{
        font-weight: 500;
        color: #6B7280;
      }
    }
}

/* Table.css */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
  }
  
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .checkmark1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: #fff;
    border: 1px solid rgba(233, 235, 239, 1);
    border-radius: 3px;
  }
  
  .container:hover input ~ .checkmark1 {
    background-color: #fff;
  }
  
  .container input:checked ~ .checkmark1 {
    background-color: #ffffff;
    border-color: #004BEC;
  }
  
  .checkmark1:after {
    content: '';
    position: absolute;
    display: none;
  }
  
  .container input:checked ~ .checkmark1:after {
    display: block;
  }
  
  .container .checkmark1:after {
    left: 5px;
    top: 2px;
    width: 3px;
    height: 7px;
    border: solid #004BEC;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

.checkmark2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #fff;
  border: 1px solid rgba(233, 235, 239, 1);
  border-radius: 3px;
}

.container:hover input ~ .checkmark2 {
  background-color: #fff;
}

.container input:checked ~ .checkmark2 {
  background-color: #fff; /* Background color when checked */
  border-color: #000; /* Border color when checked */
}

.checkmark2:after {
  content: '';
  position: absolute;
  display: none;
  left: 50%;
  top:50%;
  width: 9px;
  height: 9px;
  transform: translate(-50%,-50%);
  background-color: #000; 
  border-radius: 2px; 
}

.container input:checked ~ .checkmark2:after {
  display: block;
}

  .no-result {
    top: 0%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);

    .no-img {
      display: flex;
      justify-content: center;
      margin-bottom: 34px;
    }
    .nor-text {
      font-size: 24px;
      line-height: 31px;
      font-weight: 500;
      text-align: center;
      color: #374151;
    }

}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1003;
  animation: fadeIn 0.4s;
}
.selected-filters {
  margin-left: 0px !important;
  gap: 14px;
  width: 100%;
  flex-wrap: wrap;
  .filter-tag {
    background: #F3F4F6;
    padding: 7px 12px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    color: #111827;
    margin-left: 0 !important;
    min-width: fit-content;
    .is {
      color: #9CA3AF;
    }
    span {
      margin-left: 4px;
    }
  }
  .tag-scroll {
    min-width: fit-content;
  }
}
.filter-scroll {
  width: 60%;
  overflow: auto;
  padding-bottom: 4px;
}

  @media screen and (max-width: 1300px) {
    .offer-table-desc {
      width: 15%;
    }
    .offers-hub-table, table, tbody, tr, .publish {
      font-size: 12px !important;
    }
    .offers-hub-table {
      button {
        font-size: 12px;
        padding: 8px 14px !important;
      }
    }
    .offers-hub-table table thead td {
      font-size: 12px;
    }
  }
  